import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import ContentLoader from 'react-content-loader';

/**
 * Returns value from dictionary.
 *
 * @param {Object} dictionary
 * @param {String} key
 *
 * @returns {*|{title}}
 */
const getDictionaryValue = (dictionary, key) => {
  const defaultValue = {title: key};
  if (!dictionary) {
    return defaultValue;
  }
  if (!dictionary[key]) {
    return defaultValue;
  }

  return dictionary[key] ? dictionary[key] : defaultValue;
};

const pathFromQueryString = () => {
  const loc = window.location.href.indexOf('?');
  if (loc === -1) {
    return '';
  }
  const params = window.location.href.slice(loc + 1).split('&');
  const paramsMap = params.map(function (val) {
    const param = val.split('=');
    this.accumulator.push(param[1]);
    return this.accumulator.join('/');
  }, {accumulator: []});

  return paramsMap[paramsMap.length - 1];
}

/**
 * Builds sections list.
 *
 * @param {Array} acc
 * @param {Object} section
 * @param {String} path
 * @param {Object} sections
 *
 * @returns {Array}
 */
const buildSection = (acc, section, path = '', sections) => {
  const sectionKey = '_section';
  if (section[sectionKey]) {
    const sectionID = section[sectionKey];
    if (sections[sectionID]) {
      const sectionDefinition = sections[sectionID];
      const sectionOptions = [];
      for (const innerSection in section) {
        if (innerSection !== sectionKey) {
          sectionOptions.push(innerSection);
        }
      }
      sectionDefinition['options'] = sectionOptions;
      sectionDefinition['path'] = path;
      acc.push(sectionDefinition);
    } else {
      const sectionOptions = [];
      for (const innerSection in section) {
        if (innerSection !== sectionKey) {
          sectionOptions.push(innerSection);
        }
      }
      const rawSectionDefinition = {
        title: section[sectionKey],
        query_parameter: section[sectionKey],
        options: sectionOptions,
        path: path,
      };
      acc.push(rawSectionDefinition);
    }
  }

  Object.keys(section).forEach((sectionName) => {
    if (section[sectionName] && section[sectionName][sectionKey]) {
      const newPath = path ? path + '/' + sectionName : sectionName;
      let res = buildSection([], section[sectionName], newPath, sections);
      res.forEach(r => acc.push({...r}));
    }
  });

  return acc;
}

/**
 * Preloader component.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Preloader = () => {
  const fillColor = '#f0f1f1';
  const speed = 2;
  return <div className='py-4'>
    <div className='text-center'>
      <div>
        <ContentLoader speed={speed} width={400} height={45} viewBox='0 0 320 45'>
          <rect x='0' y='17' rx='4' ry='4' width='320' height='40' fill={fillColor}></rect>
        </ContentLoader>
      </div>
      <div>
        <ContentLoader speed={speed} height={120} viewBox='0 0 400 120'>
          <rect x='0' y='17' rx='4' ry='4' width='100%' height='16' fill={fillColor}></rect>
          <rect x='0' y='40' rx='3' ry='3' width='400' height='16' fill={fillColor}></rect>
        </ContentLoader>
      </div>
    </div>
  </div>;
};

/**
 * Selector section component.
 *
 * @param {Boolean} isActive
 * @param {String} title
 * @param {Array} options
 * @param {String} path
 * @param {Object} dictionary
 * @param {Function} pathHandler
 * @param {String} q
 * @returns {JSX.Element}
 * @constructor
 */
const SelectorSection = ({isActive, title, options, path, dictionary, pathHandler, q}) => {
  const handleClick = (e) => {
    /**
     * @type {HTMLButtonElement}
     */
    const clickedElement = e.target;
    if (clickedElement.dataset['path']) {
      pathHandler(`${clickedElement.dataset['path']}/${clickedElement.value}`)
    } else {
      pathHandler(`${clickedElement.value}`)
    }
  }

  const sectionCSSClasses = ['nested-option-selector__section'];
  if (isActive) {
    sectionCSSClasses.push('active');
  }
  return (
    <div className={sectionCSSClasses.join(' ')} data-path={path}>
      <h3 className='h--smaller mb-0 nested-option-selector__section-label'>{title}</h3>
      <div className='nested-option-selector__section-options'>
        {options.length > 0 && options.map((e, i) => {
          const buttonData = getDictionaryValue(dictionary, e);
          return <button key={i}
                         className='btn btn-cta'
                         value={e}
                         data-q={q}
                         data-path={path.toString()}
                         data-value={path ? `${path.toString()}/${e}`: e}
                         onClick={handleClick}
                         title={buttonData.tooltip ? buttonData.tooltip: ''}
                         type={'button'}>
            {buttonData.title}
          </button>
        })}
      </div>
    </div>
  );
};

/**
 * Release panel component.
 *
 * @param {Object} release
 * @param {String} release.os
 * @param {String} release.arch
 * @param {String} release.os_version
 * @param {String} release.distro_name
 * @param {String} release.distro_version
 * @param {String} release.details
 * @returns {JSX.Element}
 * @constructor
 */
const ReleasePanel = ({release}) => {
  const {details} = release;
  const {os, arch} = release;
  const {os_version, distro_name, distro_version} = release;

  const [title, setTitle] = useState('');

  useEffect(() => {
    const localizedTitle = I18n.t('nested_option_selector.download_installer', {
      os: os ? os : '',
      os_version: os_version ? os_version : '',
      distro_name: distro_name ? distro_name : '',
      distro_version: distro_version ? distro_version : '',
      arch: arch ? arch : '',
    }).replace(/\s{2,}/g, " ");

    setTitle(localizedTitle);
  }, [release]);

  return (
    <div className='nested-option-selector__details'>
      <div className='card'>
        <h5 className='card-header'>{title}</h5>
        <div className='card-body' dangerouslySetInnerHTML={{__html: details}}></div>
      </div>
    </div>
  );
};

/**
 * Nested option selector component.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const NestedOptionSelector = ({pageData}) => {
  const [currentPath, setCurrentPath] = useState(pathFromQueryString());
  const [instantiatedFromQueryString, setInstantiatedFromQueryString] = useState(false);
  const ref = useRef();

  const componentHeader = pageData.header ? pageData.header : {
    title: '',
    subtitle: 'Select Target Platform',
    description: 'Click on the green buttons that describe your target platform. Only supported platforms will be shown.'
  }
  const sectionsList= buildSection([], pageData.structure, '', pageData.sections);
  const dictionary = pageData.dictionary;
  const releases = pageData.releases;

  useEffect(() => {
    if (sectionsList.length && !instantiatedFromQueryString) {
      const pathFromQS = pathFromQueryString();
      if (pathFromQS) {
        pathHandler(pathFromQS);
        setInstantiatedFromQueryString(true);
      }
    }
  }, []);

  const pathHandler = val => {
    setCurrentPath(val);
    if (ref.current) {
      /**
       * @type {HTMLButtonElement} ref.current
       */
      const currentElement = ref.current;
      const activeButtons = currentElement.querySelectorAll('.btn.active');
      activeButtons.forEach(button => button.classList.remove('active'));

      const valParts = val.split('/');
      const activeButtonsList = [];
      while (valParts.length) {
        let valPart = valParts.join('/').trim();
        const btn = currentElement.querySelector(`.btn[data-value='${valPart}']`);
        if (btn) {
          activeButtonsList.push(btn);
          btn.classList.add('active');
        }
        valParts.pop();
      }

      const params = new URLSearchParams();
      activeButtonsList.reverse().forEach(btn => params.set(btn.dataset['q'], btn.value));
      if (params.size) {
        const url = window.location.pathname + '?' + params.toString();
        window.history.replaceState({}, '', url);
      }
    }
  }

  const pathParts = currentPath.split('/');
  const activePathList = [];
  while (pathParts.length) {
    let pathPart = pathParts.join('/').trim();
    activePathList.push(pathPart);
    pathParts.pop();
  }

  return (
    <div className='nested-option-selector' ref={ref}>
      <div className='nested-option-selector__intro'>
        <h1 className='h–large text-center'>{componentHeader.title}</h1>
        <h2 className='h--smallest'>{componentHeader.subtitle}</h2>
        <p className='p--medium' dangerouslySetInnerHTML={{__html: componentHeader.description}}></p>
      </div>
      <div className='nested-option-selector__sections'>
        {sectionsList.length > 0 && sectionsList.map((section, sectionIdx) => {
          const {title, query_parameter, path, options} = section;
          let isActive = activePathList.includes(path) || path.toString().length === 0;

          return <SelectorSection key={sectionIdx}
                                  isActive={isActive}
                                  title={title}
                                  pathHandler={pathHandler}
                                  q={query_parameter}
                                  path={path}
                                  options={options}
                                  dictionary={dictionary}/>
        })}
      </div>

      {releases && releases[currentPath] && <ReleasePanel release={releases[currentPath]}/>}
    </div>
  )
}

export default NestedOptionSelector;
